.north-star-loader {
  animation: north-star-pulse 3.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes north-star-pulse {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(360deg) scale(1.5);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(720deg);
  }
}
