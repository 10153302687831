*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.right-0 {
  right: 0px;
}

.top-0 {
  top: 0px;
}

.top-14 {
  top: 3.5rem;
}

.isolate {
  isolation: isolate;
}

.z-backdrop {
  z-index: 1040;
}

.z-drawer {
  z-index: 1000;
}

.z-modal {
  z-index: 1060;
}

.z-popover {
  z-index: 1070;
}

.z-slideout {
  z-index: 1050;
}

.z-tooltip {
  z-index: 1080;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-start-2 {
  grid-column-start: 2;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.-m-2 {
  margin: -0.5rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-4 {
  margin: 1rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-mr-1 {
  margin-right: -0.25rem;
}

.-mr-1\.5 {
  margin-right: -0.375rem;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-mt-1\.5 {
  margin-top: -0.375rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.\!table {
  display: table !important;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[2\/1\] {
  aspect-ratio: 2/1;
}

.aspect-\[3\/1\] {
  aspect-ratio: 3/1;
}

.aspect-\[5\/1\] {
  aspect-ratio: 5/1;
}

.aspect-\[5\/2\] {
  aspect-ratio: 5/2;
}

.aspect-\[7\/3\] {
  aspect-ratio: 7/3;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-1 {
  height: 0.25rem;
}

.h-1\.5 {
  height: 0.375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: 0.5rem;
}

.h-2\.5 {
  height: 0.625rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: 0.75rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-full {
  height: 100%;
}

.h-full-minus-header {
  height: calc(100vh - 3.5rem);
}

.h-screen {
  height: 100vh;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-\[65vh\] {
  max-height: 65vh;
}

.max-h-full {
  max-height: 100%;
}

.min-h-\[28rem\] {
  min-height: 28rem;
}

.min-h-full {
  min-height: 100%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-1\/4 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: 0.5rem;
}

.w-20 {
  width: 5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-\[7rem\] {
  min-width: 7rem;
}

.min-w-fit {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.grow {
  flex-grow: 1;
}

.origin-top-right {
  transform-origin: top right;
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-2 {
  --tw-translate-x: 0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-3 {
  --tw-translate-x: 0.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-bounce {
  animation: bounce 1s infinite;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-\[1fr\2c _2fr\] {
  grid-template-columns: 1fr 2fr;
}

.grid-cols-\[3fr\2c _5fr\2c _1fr\] {
  grid-template-columns: 3fr 5fr 1fr;
}

.grid-cols-\[4rem\2c _1fr\] {
  grid-template-columns: 4rem 1fr;
}

.grid-cols-\[6rem\2c _1fr\] {
  grid-template-columns: 6rem 1fr;
}

.grid-cols-\[6rem\2c _repeat\(2\2c _4rem\)\] {
  grid-template-columns: 6rem repeat(2, 4rem);
}

.grid-cols-\[6rem\2c _repeat\(4\2c _4rem\)\] {
  grid-template-columns: 6rem repeat(4, 4rem);
}

.grid-cols-\[repeat\(3\2c _1fr\)_3fr\] {
  grid-template-columns: repeat(3, 1fr) 3fr;
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-\[repeat\(minmax\(0\2c _1fr\)\)\] {
  grid-template-rows: repeat(minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-items-center {
  place-items: center;
}

.place-items-stretch {
  place-items: stretch;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.\!justify-start {
  justify-content: flex-start !important;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-2 {
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

.gap-x-4 {
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-neutral-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: hsl(0 0% 86% / var(--tw-divide-opacity));
}

.place-self-center {
  place-self: center;
}

.self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-apricot-600 {
  --tw-border-opacity: 1;
  border-color: hsl(44 100% 42% / var(--tw-border-opacity));
}

.border-bera {
  --tw-border-opacity: 1;
  border-color: hsl(199 100% 50% / var(--tw-border-opacity));
}

.border-bera-600 {
  --tw-border-opacity: 1;
  border-color: hsl(199 100% 42% / var(--tw-border-opacity));
}

.border-grass-600 {
  --tw-border-opacity: 1;
  border-color: hsl(149 68% 42% / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: hsl(210 33% 98% / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: hsl(0 0% 94% / var(--tw-border-opacity));
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: hsl(0 0% 86% / var(--tw-border-opacity));
}

.border-strawberry-600 {
  --tw-border-opacity: 1;
  border-color: hsl(358 100% 42% / var(--tw-border-opacity));
}

.border-transparent {
  border-color: transparent;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-apricot {
  --tw-bg-opacity: 1;
  background-color: hsl(44 100% 50% / var(--tw-bg-opacity));
}

.bg-apricot-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(44 100% 82% / var(--tw-bg-opacity));
}

.bg-bera {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 50% / var(--tw-bg-opacity));
}

.bg-bera-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 82% / var(--tw-bg-opacity));
}

.bg-bera-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 74% / var(--tw-bg-opacity));
}

.bg-bera-300 {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 66% / var(--tw-bg-opacity));
}

.bg-bera-50 {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 96% / var(--tw-bg-opacity));
}

.bg-bera-600 {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 42% / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-grass {
  --tw-bg-opacity: 1;
  background-color: hsl(149 68% 50% / var(--tw-bg-opacity));
}

.bg-grass-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(149 68% 82% / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(0 0% 100% / var(--tw-bg-opacity));
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(210 33% 98% / var(--tw-bg-opacity));
}

.bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: hsl(0 0% 94% / var(--tw-bg-opacity));
}

.bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: hsl(0 0% 30% / var(--tw-bg-opacity));
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: hsl(0 0% 0% / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: hsl(22 96% 50% / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-strawberry {
  --tw-bg-opacity: 1;
  background-color: hsl(358 100% 50% / var(--tw-bg-opacity));
}

.bg-strawberry-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(358 100% 82% / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}

.fill-bera {
  fill: hsl(199 100% 50%);
}

.fill-black {
  fill: #000;
}

.fill-white {
  fill: #fff;
}

.stroke-transparent {
  stroke: transparent;
}

.p-1 {
  padding: 0.25rem;
}

.p-1\.5 {
  padding: 0.375rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-2\.5 {
  padding-bottom: 0.625rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-\[2px\] {
  padding-top: 2px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-sans {
  font-family: IBM Plex Sans, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-4 {
  line-height: 1rem;
}

.leading-none {
  line-height: 1;
}

.text-apricot-900 {
  --tw-text-opacity: 1;
  color: hsl(44 100% 18% / var(--tw-text-opacity));
}

.text-bera {
  --tw-text-opacity: 1;
  color: hsl(199 100% 50% / var(--tw-text-opacity));
}

.text-bera-200 {
  --tw-text-opacity: 1;
  color: hsl(199 100% 74% / var(--tw-text-opacity));
}

.text-bera-600 {
  --tw-text-opacity: 1;
  color: hsl(199 100% 42% / var(--tw-text-opacity));
}

.text-bera-900 {
  --tw-text-opacity: 1;
  color: hsl(199 100% 18% / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-grass-900 {
  --tw-text-opacity: 1;
  color: hsl(149 68% 18% / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: hsl(0 0% 60% / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: hsl(0 0% 45% / var(--tw-text-opacity));
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: hsl(0 0% 20% / var(--tw-text-opacity));
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: hsl(0 0% 0% / var(--tw-text-opacity));
}

.text-stage-boredom {
  --tw-text-opacity: 1;
  color: hsl(22 96% 50% / var(--tw-text-opacity));
}

.text-stage-dating {
  --tw-text-opacity: 1;
  color: hsl(274 100% 42% / var(--tw-text-opacity));
}

.text-stage-divorce {
  --tw-text-opacity: 1;
  color: hsl(44 100% 50% / var(--tw-text-opacity));
}

.text-stage-love {
  --tw-text-opacity: 1;
  color: hsl(324 87% 50% / var(--tw-text-opacity));
}

.text-stage-new {
  --tw-text-opacity: 1;
  color: hsl(199 100% 50% / var(--tw-text-opacity));
}

.text-strawberry {
  --tw-text-opacity: 1;
  color: hsl(358 100% 50% / var(--tw-text-opacity));
}

.text-strawberry-900 {
  --tw-text-opacity: 1;
  color: hsl(358 100% 18% / var(--tw-text-opacity));
}

.text-transparent {
  color: transparent;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/50 {
  color: rgb(255 255 255 / 0.5);
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.will-change-contents {
  will-change: contents;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.\[text-shadow\:0_1px_2px_hsl\(199_100\%_12\%\/\.6\)\] {
  text-shadow: 0 1px 2px hsl(199 100% 12%/0.6);
}

.last\:border-b-0:last-child {
  border-bottom-width: 0px;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-bera:hover {
  --tw-border-opacity: 1;
  border-color: hsl(199 100% 50% / var(--tw-border-opacity));
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.hover\:bg-bera:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 50% / var(--tw-bg-opacity));
}

.hover\:bg-bera-50:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 96% / var(--tw-bg-opacity));
}

.hover\:bg-bera-700:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 34% / var(--tw-bg-opacity));
}

.hover\:bg-bera-75:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 90% / var(--tw-bg-opacity));
}

.hover\:bg-neutral-300:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(0 0% 94% / var(--tw-bg-opacity));
}

.hover\:bg-white\/25:hover {
  background-color: rgb(255 255 255 / 0.25);
}

.hover\:fill-bera-300:hover {
  fill: hsl(199 100% 66%);
}

.hover\:stroke-grass\/25:hover {
  stroke: hsl(149 68% 50% / 0.25);
}

.hover\:stroke-strawberry\/25:hover {
  stroke: hsl(358 100% 50% / 0.25);
}

.hover\:stroke-\[8\]:hover {
  stroke-width: 8;
}

.hover\:text-bera:hover {
  --tw-text-opacity: 1;
  color: hsl(199 100% 50% / var(--tw-text-opacity));
}

.hover\:text-bera-300:hover {
  --tw-text-opacity: 1;
  color: hsl(199 100% 66% / var(--tw-text-opacity));
}

.hover\:text-bera-500:hover {
  --tw-text-opacity: 1;
  color: hsl(199 100% 50% / var(--tw-text-opacity));
}

.hover\:text-bera-600:hover {
  --tw-text-opacity: 1;
  color: hsl(199 100% 42% / var(--tw-text-opacity));
}

.hover\:text-bera-700:hover {
  --tw-text-opacity: 1;
  color: hsl(199 100% 34% / var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-bera:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(199 100% 50% / var(--tw-ring-opacity));
}

.focus\:ring-bera-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(199 100% 58% / var(--tw-ring-opacity));
}

.focus\:ring-bera-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: hsl(199 100% 50% / var(--tw-ring-opacity));
}

.focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-bera-100:focus {
  --tw-ring-offset-color: hsl(199 100% 82%);
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:bg-bera-200:disabled {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 74% / var(--tw-bg-opacity));
}

.disabled\:bg-gray-200:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.disabled\:bg-transparent:disabled {
  background-color: transparent;
}

.disabled\:text-black:disabled {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.disabled\:text-neutral-300:disabled {
  --tw-text-opacity: 1;
  color: hsl(0 0% 94% / var(--tw-text-opacity));
}

.disabled\:text-white:disabled {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.disabled\:opacity-50:disabled {
  opacity: 0.5;
}

.disabled\:\[text-shadow\:none\]:disabled {
  text-shadow: none;
}

.ui-selected\:border-l-bera[data-headlessui-state~='selected'] {
  --tw-border-opacity: 1;
  border-left-color: hsl(199 100% 50% / var(--tw-border-opacity));
}

.ui-selected\:font-semibold[data-headlessui-state~='selected'] {
  font-weight: 600;
}

.ui-selected\:text-bera[data-headlessui-state~='selected'] {
  --tw-text-opacity: 1;
  color: hsl(199 100% 50% / var(--tw-text-opacity));
}

:where([data-headlessui-state~='selected']) .ui-selected\:border-l-bera {
  --tw-border-opacity: 1;
  border-left-color: hsl(199 100% 50% / var(--tw-border-opacity));
}

:where([data-headlessui-state~='selected']) .ui-selected\:font-semibold {
  font-weight: 600;
}

:where([data-headlessui-state~='selected']) .ui-selected\:text-bera {
  --tw-text-opacity: 1;
  color: hsl(199 100% 50% / var(--tw-text-opacity));
}

.dark .dark\:divide-slate-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-divide-opacity));
}

.dark .dark\:divide-slate-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-divide-opacity));
}

.dark .dark\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.dark .dark\:border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.dark .dark\:border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.dark .dark\:border-transparent {
  border-color: transparent;
}

.dark .dark\:bg-apricot-500 {
  --tw-bg-opacity: 1;
  background-color: hsl(44 100% 50% / var(--tw-bg-opacity));
}

.dark .dark\:bg-bera {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 50% / var(--tw-bg-opacity));
}

.dark .dark\:bg-bera-800 {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 26% / var(--tw-bg-opacity));
}

.dark .dark\:bg-black\/10 {
  background-color: rgb(0 0 0 / 0.1);
}

.dark .dark\:bg-black\/50 {
  background-color: rgb(0 0 0 / 0.5);
}

.dark .dark\:bg-black\/\[\.125\] {
  background-color: rgb(0 0 0 / 0.125);
}

.dark .dark\:bg-grass-800 {
  --tw-bg-opacity: 1;
  background-color: hsl(149 68% 26% / var(--tw-bg-opacity));
}

.dark .dark\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.dark .dark\:bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: hsl(0 0% 30% / var(--tw-bg-opacity));
}

.dark .dark\:bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.dark .dark\:bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.dark .dark\:bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.dark .dark\:bg-strawberry-700 {
  --tw-bg-opacity: 1;
  background-color: hsl(358 100% 34% / var(--tw-bg-opacity));
}

.dark .dark\:bg-transparent {
  background-color: transparent;
}

.dark .dark\:fill-slate-200 {
  fill: #e2e8f0;
}

.dark .dark\:fill-slate-300 {
  fill: #cbd5e1;
}

.dark .dark\:fill-slate-600 {
  fill: #475569;
}

.dark .dark\:fill-slate-700 {
  fill: #334155;
}

.dark .dark\:fill-white {
  fill: #fff;
}

.dark .dark\:stroke-slate-500 {
  stroke: #64748b;
}

.dark .dark\:stroke-slate-600 {
  stroke: #475569;
}

.dark .dark\:stroke-slate-700 {
  stroke: #334155;
}

.dark .dark\:text-neutral-300 {
  --tw-text-opacity: 1;
  color: hsl(0 0% 94% / var(--tw-text-opacity));
}

.dark .dark\:text-neutral-800 {
  --tw-text-opacity: 1;
  color: hsl(0 0% 20% / var(--tw-text-opacity));
}

.dark .dark\:text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.dark .dark\:text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.dark .dark\:text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.dark .dark\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dark .dark\:hover\:border-slate-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.dark .dark\:hover\:bg-bera-800:hover {
  --tw-bg-opacity: 1;
  background-color: hsl(199 100% 26% / var(--tw-bg-opacity));
}

.dark .dark\:hover\:bg-slate-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.dark .dark\:hover\:text-bera-50:hover {
  --tw-text-opacity: 1;
  color: hsl(199 100% 96% / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-\[80vw\] {
    max-width: 80vw;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:gap-2 {
    gap: 0.5rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:p-0 {
    padding: 0px;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:duration-700 {
    transition-duration: 700ms;
  }
}

@media (min-width: 768px) {
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:aspect-\[3\/1\] {
    aspect-ratio: 3/1;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:pr-xs {
    padding-right: 20rem;
  }
}

@media (min-width: 1280px) {
  .xl\:aspect-\[4\/1\] {
    aspect-ratio: 4/1;
  }
}

.\[\&\:is\(\.bc\[data-headlessui-state\=selected\]\)\]\:border-bera:is(.bc[data-headlessui-state='selected']) {
  --tw-border-opacity: 1;
  border-color: hsl(199 100% 50% / var(--tw-border-opacity));
}

.\[\&\:is\(\.bc\[data-headlessui-state\=selected\]\)\]\:font-semibold:is(.bc[data-headlessui-state='selected']) {
  font-weight: 600;
}

.\[\&\:is\(\.bc\[data-headlessui-state\=selected\]\)\]\:text-bera:is(.bc[data-headlessui-state='selected']) {
  --tw-text-opacity: 1;
  color: hsl(199 100% 50% / var(--tw-text-opacity));
}

.\[\&\:is\(\.fac\[data-headlessui-state\=selected\]\)\]\:border-bera:is(.fac[data-headlessui-state='selected']) {
  --tw-border-opacity: 1;
  border-color: hsl(199 100% 50% / var(--tw-border-opacity));
}

.\[\&\:is\(\.fac\[data-headlessui-state\=selected\]\)\]\:font-semibold:is(.fac[data-headlessui-state='selected']) {
  font-weight: 600;
}

.\[\&\:is\(\.fac\[data-headlessui-state\=selected\]\)\]\:text-bera:is(.fac[data-headlessui-state='selected']) {
  --tw-text-opacity: 1;
  color: hsl(199 100% 50% / var(--tw-text-opacity));
}

.\[\&\>\*\]\:pointer-events-none > * {
  pointer-events: none;
}
